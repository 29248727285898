import Storage from '@aws-amplify/storage';
import {
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
  IonProgressBar,
  IonToolbar,
} from '@ionic/react';
import { useWorkspace } from 'hooks/useWorkspace';
import { AccountKind } from 'interfaces/AccountKind';
import { MentionKind } from 'interfaces/Comment';
import {
  addCircleOutline as addIcon,
  attachOutline as fileIcon,
  imageOutline as photoIcon,
} from 'ionicons/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { v4 as uuidv4 } from 'uuid';

import CommentInput from './CommentInput';

const { REACT_APP_AWS_REGION = 'us-east-1', REACT_APP_WORK_ORDERS_S3_BUCKET } = process.env;

Storage.configure({
  AWSS3: {
    bucket: REACT_APP_WORK_ORDERS_S3_BUCKET,
    region: REACT_APP_AWS_REGION,
  },
});

export interface FileUpload {
  uploadId: string;
  name: string;
  mimetype: string;
  data: File;
}

interface UploadedFile {
  uploadId: string;
  name: string;
  mimetype: string;
  storageKey: string;
}

export interface CommentBarProps {
  allowMentions?: boolean;
  addCommentIcon?: string;
  placeholder?: string;
  uploadDirectory?: string;
  onAddComment: (comment: string) => Promise<unknown>;
  onUpload?: (upload: UploadedFile) => Promise<unknown>;
  /** Render props to allow render component from parent that can initiate the file browser */
  renderBrowseFiles?: (props: { onBrowse: (onlyPhotos?: boolean) => void }) => JSX.Element;
}

const useStyles = createUseStyles({
  root: {
    fontSize: '0.875rem',
    '.md & ion-toolbar:first-child': {
      '--border-width': '1px 0 0',
    },
    '& ion-toolbar': {
      contain: 'initial',
    },
  },
  input: {
    paddingLeft: 'calc(var(--ion-padding, 16px) * 0.5)',
  },
});

const CommentBar: React.FC<CommentBarProps> = ({
  allowMentions,
  addCommentIcon = addIcon,
  placeholder,
  uploadDirectory,
  onAddComment,
  onUpload,
  renderBrowseFiles,
}) => {
  const classes = useStyles();

  const toolbarRef = useRef<HTMLIonToolbarElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [comment, setComment] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isAddingComment, setIsAddingComment] = useState(false);

  // `CommentInput` only binds a "default" value that is uncontrolled (can't change after init).
  // Force component to remount and render a new, programatically set value (new default value)
  // by setting `commentInputReload` state.
  const [commentInputReload, setCommentInputReload] = useState(new Date().valueOf());

  const {
    workspace: { assignees, operatorId, organizationId, teams, teamId },
  } = useWorkspace();

  useEffect(() => {
    const toolbar = toolbarRef.current;

    if (!toolbar) return;

    // Override .toolbar-container shadow-root styles
    // Shadow piercing psuedo-selectors no longer generally available, so style element directly added
    // Overridding properties to allow mentions selection menu to overflow outside of container
    const style = document.createElement('style');
    style.innerHTML =
      '.toolbar-container { contain: initial !important; overflow: visible !important; }';
    toolbar.appendChild(style);
    toolbar.shadowRoot?.appendChild(style);
  }, []);

  const mentionables = useMemo(() => {
    return [
      ...assignees
        .filter((assignee) => assignee.kind === AccountKind.User)
        .map((assignee) => ({
          id: assignee.id,
          name: assignee.name,
          kind: MentionKind.User,
          detail: assignee.id === operatorId ? 'you' : undefined,
        })),
      ...teams.map((team) => ({
        id: team.id,
        name: team.name,
        kind: MentionKind.Team,
        detail: team.id === teamId ? 'your team' : undefined,
      })),
      {
        id: organizationId,
        name: 'everyone',
        kind: MentionKind.Organization,
      },
    ];
  }, [assignees, operatorId, organizationId, teamId, teams]);

  const addComment = useCallback(
    async (newComment) => {
      if (!newComment) {
        return;
      }

      setIsAddingComment(true);

      // Must be greater than 0
      setUploadPercentage(1);

      try {
        await onAddComment(newComment);
        setComment('');
        setCommentInputReload(new Date().valueOf()); // Forced input remount to update with new default value
      } catch (_err) {
        // Noop
      }

      setIsAddingComment(false);
      setUploadPercentage(0);
    },
    [onAddComment]
  );

  const uploadFile = useCallback(
    async (item: FileUpload) => {
      if (!onUpload) {
        return false;
      }

      const fileName = uploadDirectory ? `${uploadDirectory}/${item.uploadId}` : `${item.uploadId}`;

      const { key }: any = await Storage.put(fileName, item.data, {
        contentType: item.mimetype,
        contentDisposition: `inline; filename="${item.name}"`,
        uploadId: item.uploadId,
        progressCallback: (progress: any) => setUploadPercentage(progress.loaded / progress.total),
      });

      try {
        await onUpload({
          uploadId: item.uploadId,
          name: item.name,
          mimetype: item.mimetype,
          storageKey: `public/${key}`,
        });
      } catch (_err) {
        // Noop
      }

      setUploadPercentage(0);
    },
    [uploadDirectory, onUpload]
  );

  const onFileSelected = () => {
    if (
      fileInputRef.current &&
      fileInputRef.current.files &&
      fileInputRef.current.files.length > 0
    ) {
      const file = fileInputRef.current.files[0];
      uploadFile({
        uploadId: uuidv4(),
        name: file.name,
        mimetype: file.type,
        data: file,
      });
    }
  };

  const browseFiles = (onlyPhotos?: boolean) => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = onlyPhotos ? 'image/*' : '';
      fileInputRef.current.click();
    }
  };

  return (
    <IonFooter className={classes.root}>
      {renderBrowseFiles && renderBrowseFiles({ onBrowse: browseFiles })}

      {uploadPercentage > 0 && (
        <IonProgressBar
          type={isAddingComment ? 'indeterminate' : 'determinate'}
          value={uploadPercentage}
        />
      )}

      <IonToolbar ref={toolbarRef}>
        {onUpload && (
          <IonButtons slot="start">
            <IonButton onClick={() => browseFiles(true)}>
              <IonIcon slot="icon-only" icon={photoIcon} />
            </IonButton>
            <IonButton onClick={() => browseFiles()}>
              <IonIcon slot="icon-only" icon={fileIcon} />
            </IonButton>
          </IonButtons>
        )}
        <CommentInput
          key={commentInputReload}
          allowMentions={allowMentions}
          mentionables={mentionables}
          placeholder={placeholder}
          defaultValue={comment}
          onChange={setComment}
          onEnter={addComment}
        />
        <IonButtons slot="end">
          <IonButton disabled={!comment} onClick={() => addComment(comment)}>
            <IonIcon slot="icon-only" icon={addCommentIcon} />
          </IonButton>
        </IonButtons>
      </IonToolbar>

      <input type="file" className="ion-hide" onChange={onFileSelected} ref={fileInputRef} />
    </IonFooter>
  );
};

export default CommentBar;
