import centerOfMass from '@turf/center-of-mass';
import distance from '@turf/distance';

interface Props {
  workorderCoords: number[];
  assetLocation: GeoJSON.Feature;
}

// TODO. Refactor. Document inputs and outputs.
export function calcDistanceByLocation({ workorderCoords, assetLocation }: Props) {
  const center = centerOfMass(assetLocation);
  const d = distance(workorderCoords, center, { units: 'feet' });
  return d;
}
