import { MapStyles } from 'interfaces/Basemaps';

export const resolvers = {
  MapStyles: {
    lightUrl: ({ light }: Pick<MapStyles, 'light'>) => {
      return `mapbox://styles/${light}?optimize=true`;
    },
    darkUrl: ({ dark }: Pick<MapStyles, 'dark'>) => {
      return `mapbox://styles/${dark}?optimize=true`;
    },
  },
};
